.clearButton {
  border: none;
  outline: none;
  background: none;
}

.download {
  display: flex;
  justify-content: flex-end;
}
